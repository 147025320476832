export class dadataCheckout
{
	constructor()
	{
		this.dadata = {
			url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
			token: '776c7cd60a605b913677d43e350859b7347c16b0'
		};
		this.city = {
			text: '',
			id: ''
		};
		this.street = {
			text: '',
			id: ''
		};
		this.house = {
			text: '',
			id: ''
		};
		this.flat = '';
		this.postcode = '';
		this.tips = {
			city: [],
			street: [],
			house: []
		};
		this.popup = {};
		this.popupTips = [];
		window.dadataAddress = {};
		window.dadata = {};
		
		if(window.checkout && window.checkout.address.default.country)
			window.dadata.country = window.checkout.address.default.country;
		
		window.dadata.onSelectDadataItem = (type) => {
			if(window.checkout)
			{
				switch(type)
				{
					case 'city':
						let cityFiasId = 
							window.dadataAddress.city.data.city_fias_id 
							|| window.dadataAddress.city.data.settlement_fias_id 
							|| window.dadataAddress.city.data.area_fias_id 
							|| window.dadataAddress.city.data.region_fias_id 
							|| window.checkout.address.default.cityFiasId;
							
						window.checkout.updateCheckoutWindowData(window.dadataAddress.city.data.country, window.dadataAddress.city.data.region, {name: window.dadataAddress.city.text, fias: cityFiasId, zip: window.dadataAddress.city.data.postal_code});

						window.citiesPopup.updateTitles(window.dadataAddress.city.text)

						window.checkout.city.checked = window.dadataAddress.city.text

						window.checkout.selectedValues.user.address.city = this.city
					break;
					
					case 'street':
						window.checkout.selectedValues.user.address.street = this.street
					break;
					
					case 'house':
						window.checkout.selectedValues.user.address.house = this.house
					break;

					case 'popup':
						const {data: popupAddressData} = this.popup;

						window.popupPickup.selectedAddress = popupAddressData;

						if (popupAddressData.geo_lat && popupAddressData.geo_lon)
							window.popupPickup.searchPointsByAddressCoords(popupAddressData.geo_lat, popupAddressData.geo_lon);
					break;
				}
			}
		};
		
		window.dadata.resetAddressInputs = () => {
			this.clearValues('city');
		};
		
		window.dadata.setItem = (type, item) => {
			switch(type)
			{
				case 'city':
					this.city = item;
				break;
				
				case 'street':
					this.street = item;
				break;
				
				case 'house':
					this.house = item;
				break;
				
				case 'flat':
					this.flat = item;
				break;
			}
		};
		
		this.currentInput = null;
		this.dadataPages = document.querySelectorAll('.ds-dadata-page');
		this.flatInputs = document.querySelectorAll("input[data-id='dadata-flat-input']");

		this.allInputsCity = []
		const allCities = document.querySelectorAll("input[data-id='dadata-city-input']");
		
		allCities.forEach(input => {
			this.allInputsCity.push(
			{
				id: input.id,
				type: 'city',
				input: input,
				list: input.nextElementSibling
			});
		});

		this.allInputsStreet = []
		const allStreets = document.querySelectorAll("input[data-id='dadata-street-input']");
		
		allStreets.forEach(input => {
			this.allInputsStreet.push(
			{
				id: input.id,
				type: 'street',
				input: input,
				list: input.nextElementSibling
			});
		});
		
		this.allInputsHouses = []
		const allHouses = document.querySelectorAll("input[data-id='dadata-house-input']");
		
		allHouses.forEach(input => {
			this.allInputsHouses.push(
			{
				id: input.id,
				type: 'house',
				input: input,
				list: input.nextElementSibling
			});
		});
		
		this.allInputsPopupAddress = []
		const allPopupAddress = document.querySelectorAll("input[data-id='dadata-popup-input']");
		
		allPopupAddress.forEach(input => {
			this.allInputsPopupAddress.push(
			{
				id: input.id,
				type: 'popup',
				input: input,
				list: input.nextElementSibling
			});
		});

		this.init();
	}

	init()
	{
		if(this.allInputsCity.length)
		{
			this.allInputsCity.forEach(cityData => {
				this.initListeners(cityData, this.tips.city);
			});
		}
		
		if(this.allInputsStreet.length)
		{
			this.allInputsStreet.forEach(streetData => {
				this.initListeners(streetData, this.tips.street);
			});
		}
		
		if(this.allInputsHouses.length)
		{
			this.allInputsHouses.forEach(houseData => {
				this.initListeners(houseData, this.tips.house);
			});
		}

		if(this.allInputsPopupAddress.length)
		{
			this.allInputsPopupAddress.forEach(popupAddressData => {
				this.initListeners(popupAddressData, this.popupTips);
			});
		}

		const misClick = function ({target})
		{
			if(!(target.closest(`[data-content="city-list"]`) || target.closest(`input[data-id='dadata-city-input']`)))
			{
				this.allInputsCity.forEach(cityData => {
					if(cityData.list)
						cityData.list.removeAttribute('data-open')
				});
			}
			
			if(!(target.closest(`[data-content="street-list"]`) || target.closest(`input[data-id='dadata-street-input']`)))
			{
				this.allInputsStreet.forEach(streetData => {
					if(streetData.list)
						streetData.list.removeAttribute('data-open');
				});
			}
			
			if(!(target.closest(`[data-content="house-list"]`) || target.closest(`input[data-id='dadata-house-input']`)))
			{
				this.allInputsHouses.forEach(houseData => {
					if(houseData.list)
						houseData.list.removeAttribute('data-open')
				});
			}

			if(!(target.closest(`[data-content="popup-address"]`) || target.closest(`input[data-id='dadata-popup-input']`)))
			{
				this.allInputsPopupAddress.forEach(popupAddressData => {
					if(popupAddressData.list)
						popupAddressData.list.removeAttribute('data-open')
				});
			}
		}

		this.dadataPages.forEach((dadataPage) => {
			dadataPage.addEventListener('click', misClick.bind(this));
		});

		if(this.flatInputs.length)
		{
			this.flatInputs.forEach(flatInput => {
				flatInput.addEventListener('input', ({target}) => {
					this.flat = target.value;
					window.dadataAddress.flat = this.flat;

					//записываем квартиру в выбранные значение
					if(window.checkout)
						window.checkout.selectedValues.user.address.flat = target.value;
				});
			});
		}
	}

	initListeners(dataObject, tips)
	{
		dataObject.input.addEventListener('input', ({target}) => {
			const cityValue = target.value;
			this.clearValues(dataObject.type, dataObject.id);
			this.currentInput = dataObject.input;
			this.getAddressTips({query: cityValue, type: dataObject.type});
		});

		dataObject.input.addEventListener('search', ({target}) => {
			if (target.value.length <= 0)
				window.popupPickup.clearSearchData();
		});

		dataObject.input.addEventListener('change', ({target}) => {
			const item = {id: null, itemId: target.value, name: target.value};
			
			switch(dataObject.type)
			{
				case 'city':
					this.city = item;
					
					if(window.checkout)
						window.checkout.selectedValues.user.address.city = item;
				break;
				
				case 'street':
					this.street = item;
					
					if(window.checkout)
						window.checkout.selectedValues.user.address.street = item;
				break;
				
				case 'house':
					this.house = item;
					
					if(window.checkout)
						window.checkout.selectedValues.user.address.house = item;
				break;
				
				case 'popup':
					this.popup = item;
				break;
			}
			
			if(window.checkout && dataObject.type !== 'popup')
			{
				window.checkout.selectedValues.user.address.fias = "";
				window.checkout.selectedValues.user.address.zip = "";
			}
		});

		switch(dataObject.type)
		{
			case 'city':
				dataObject.list.addEventListener('click', this.selectCity.bind(this))
			break;
			
			case 'street':
				dataObject.list.addEventListener('click', this.selectStreet.bind(this))
			break;
			
			case 'house':
				dataObject.list.addEventListener('click', this.selectHouse.bind(this))
			break;
			
			case 'popup':
				dataObject.list.addEventListener('click', this.selectPopupAddress.bind(this))
			break;
		}

		dataObject.input.addEventListener('focus', () => {
			dataObject.list.setAttribute('data-open', '');
			
			if(!tips.length)
			{
				dataObject.list.innerHTML = '';
				const item = document.createElement('li');
				item.innerHTML = `<p class="select__item"><span>Введите значение, чтобы увидеть подсказки</span></p>`;
				dataObject.list.appendChild(item);
			}
		});
	}

	selectCity(e)
	{
		this.selectListItem(e, this.tips.city, this.allInputsCity.find(({id}) => id === this.currentInput.id));
	}

	selectStreet(e)
	{
		this.selectListItem(e, this.tips.street, this.allInputsStreet.find(({id}) => id === this.currentInput.id));
	}

	selectHouse(e)
	{
		this.selectListItem(e, this.tips.house, this.allInputsHouses.find(({id}) => id === this.currentInput.id));
	}

	selectPopupAddress(e)
	{
		this.selectListItem(e, this.popupTips, this.allInputsPopupAddress.find(({id}) => id === this.currentInput.id));
	}

	selectListItem({target}, tips, dataObject)
	{
		const itemButton = target.closest("[data-select-id]");

		if(itemButton)
		{
			const curId = itemButton.getAttribute('data-select-id');
			const element = tips.find(({id}) => id === curId);

			dataObject.list.removeAttribute('data-open');

			switch(dataObject.type)
			{
				case 'city':
					dataObject.input.value = element.type;
					this.city = element;
					break;

				case 'street':
					dataObject.input.value = element.text;
					this.street = element;
					break;

				case 'house':
					if (!window.checkout.selectedValues.user.address.street.id) {
						let streetInput = document.getElementById("ch-street");
						streetInput.value = element.data.street_with_type;
					}
					dataObject.input.value = element.name;
					this.house = element;
					break;

				case 'popup':
					dataObject.input.value = element.type;
					this.popup = element;
					break;

				default:
					dataObject.input.value = element.type;
					break;
			}

			window.dadataAddress = {
				city: this.city,
				street: this.street,
				house: this.house,
				flat: this.flat
			};

			window.dadata.onSelectDadataItem(dataObject.type);
		}
	}

	setCityList()
	{
		const curData = this.allInputsCity.find(({id})=>id === this.currentInput.id);
		
		if(curData)
			this.setList(curData, this.tips.city);
	}

	setStreetList()
	{
		const curData = this.allInputsStreet.find(({id})=>id === this.currentInput.id);
		
		if(curData)
			this.setList(curData, this.tips.street);
	}

	setHouseList()
	{
		const curData = this.allInputsHouses.find(({id})=>id === this.currentInput.id);
		
		if(curData)
			this.setList(curData, this.tips.house);
	}

	setPopupAddressList()
	{
		const curData = this.allInputsPopupAddress.find(({id})=>id === this.currentInput.id);
		
		if(curData)
			this.setList(curData, this.popupTips);
	}

	setList(dataObject, tips)
	{
		dataObject.list.innerHTML = '';

		if(!tips.length)
		{
			const item = document.createElement('li');
			const text = (!dataObject.input.value.length ? 'Введите значение, чтобы увидеть подсказки' : 'Не удалось ничего найти');
			item.innerHTML = `<p class="select__item"><span>${text}</span></p>`
			dataObject.list.appendChild(item);
		}
		else
		{
			tips.forEach(tip => {
				const item = document.createElement('li');
				item.innerHTML = `<button class="select__item" type="button" data-select-id="${tip.id}">${tip.fullName} <span>${tip.type}</span></button>`;
				dataObject.list.appendChild(item);
			});
		}

		dataObject.list.setAttribute('data-open', '');
	}

	clearElementData(dataObject)
	{
		dataObject.list.innerHTML = '';
		dataObject.input.value = '';

		switch(dataObject.type)
		{
			case 'street':
				this.tips.street = [];
			break;
			
			case 'house':
				this.tips.house = [];
			break;
		}
	}

	getAddressTips(payload)
	{		
		if(!payload.query.length)
		{
			switch(payload.type)
			{
				case 'city':
					this.city.text = '';
					this.city.id = '';
				break;
				
				case 'street':
					this.street.text = '';
					this.street.id = '';
				break;
				
				case 'house':
					this.house.text = '';
					this.house.id = '';
				break;
				
				case 'popup':
					this.popup = {};
				break;
			}
		}
		else
		{
			const url = this.dadata.url;
			const token = this.dadata.token;
			const list = [];

			let nameKey = 'city_with_type';
			let onlyNameKey = 'city';
			let from_bound = null;
			let to_bound = null;
			let locations = [];
			let locationsItem = {};

			if (payload.type === 'city')
			{
				from_bound = {value: 'city'};
				to_bound = {value: 'settlement'};
			}
			else if(payload.type === 'street')
			{
				nameKey = 'street_with_type';
				onlyNameKey = 'street';
				locationsItem = this.city.kladrId
					? {kladr_id: this.city.kladrId}
					: (this.city.id && this.city.id.toString().length > 10
						? {kladr_id: this.city.id}
						: {city: this.city.name}
					);
			}
			else if(payload.type === 'house')
			{
				nameKey = 'house';
				onlyNameKey = 'house';

				if(this.street.id || this.street.name)
				{
					locationsItem = this.street.kladrId
						? {kladr_id: this.street.kladrId}
						: (this.street.id && this.street.id.toString().length > 10
							? {kladr_id: this.street.id}
							: {city: this.city.name, street: this.street.name}
						);
				}
				else
				{
					locationsItem = this.city.kladrId
						? {kladr_id: this.city.kladrId}
						: (this.city.id && this.city.id.toString().length > 10
							? {kladr_id: this.city.id}
							: {city: this.city.name}
						);
				}
			} else if(payload.type === 'popup')
			{
				nameKey = 'street_with_type';
				onlyNameKey = 'street';

				locationsItem = this.city.kladrId
					? {kladr_id: this.city.kladrId}
					: (this.city.id && this.city.id.toString().length > 10
						? {kladr_id: this.city.id}
						: {city: this.city.name}
					);

				from_bound = {
					value: 'street'
				};
			}

			if(window.dadata.country && window.dadata.country !== 'Россия')
				locationsItem.country = window.dadata.country;

			locations = [locationsItem];
			
			const options = {
				method: 'POST',
				mode: 'cors',
				headers:
				{
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Token ' + token
				},
				body: JSON.stringify(
				{
					query: payload.query,
					from_bound,
					to_bound,
					locations,
					locations_boost: locations
				})
			};

			fetch(url, options)
				.then(response => response.json())
				.then(result => {
		
					result.suggestions.forEach(el => {
						let name = el.data[nameKey];
						let onlyName = el.data[onlyNameKey];
						let fullName;

						if(payload.type === 'city' && el.data['settlement'])
						{
							name = el.data['settlement'];
							fullName = el.data['settlement_type'] + '. ' + el.data['settlement'];
						}
						else if(payload.type === 'city' && el.data['city'])
						{
							name = el.data['city_type'] + '. ' + el.data['city'];
						}

						if(payload.type === 'house' || payload.type === 'popup')
						{
							name = el.value;
							if (el.data.block) {
								onlyName += ' ' + el.data['block_type'] +  ' ' + el.data['block'];
							}
						}

						if(el.data.street_type_full !== 'метро' && name)
						{
							list.push(
							{
								id: el.data.geo_lat,
								kladrId: el.data.kladr_id,
								itemId: el.data.kladr_id ? el.data.kladr_id : (el.data.country_iso_code + (el.data.postal_code ? el.data.postal_code : '') + name),
								text: name,
								name: onlyName,
								data: el.data,
								type: el.unrestricted_value,
								fullName: fullName ? fullName : name,
							});
						}
					});

					if (payload.type === 'popup')
					{
						this.popupTips = list;

						this.setPopupAddressList();
					}					
					else if(payload.type === 'city')
					{
						this.tips = {
							city: list,
							street: [],
							house: []
						};
						
						this.setCityList();
					}
					else if(payload.type === 'street')
					{
						this.tips = {
							city: this.tips.city,
							street: list,
							house: []
						};
						
						this.setStreetList();
					}
					else
					{
						this.tips = {
							city: this.tips.city,
							street: this.tips.street,
							house: list
						};
					
						this.setHouseList();
					}
				})
				.catch(error => console.warn('error', error));
		}
	}

	getAddressPostcode(value)
	{
		if(value && value.name.length)
		{
			const url = this.dadata.url;
			const token = this.dadata.token;
			const options = {
				method: 'POST',
				mode: 'cors',
				headers:
				{
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Token ' + token
				},
				body: JSON.stringify(
				{
					query: value.unrestricted_value,
					count: 1,
				})
			};

			return fetch(url, options)
				.then(response => response.json())
				.then(result => {
					try
					{
						this.postcode = (result.suggestions[0].data.postal_code ? result.suggestions[0].data.postal_code : this.postcode);
					}
					catch(e)
					{
						console.warn(e);
					}
				})
				.catch(error => console.warn('error', error));
		}
	}

	clearValues(type, id)
	{
		switch(type)
		{
			case 'city':
				this.street = {
					name: '',
					text: ''
				};
				
				if(id)
				{
					if(this.allInputsCity.length)
					{
						this.allInputsCity.forEach(cityData => {
							if(cityData.id !== id)
							{
								this.clearElementData(cityData);
							}
						});
					}
				}
				
				if(this.allInputsStreet.length)
				{
					this.allInputsStreet.forEach(streetData => {
						this.clearElementData(streetData);
					});
				}
				
				
				if(window.checkout)
					window.checkout.selectedValues.user.address.street = {text: '', name: ''}

			//no break;
			// eslint-disable-next-line no-fallthrough
			case 'street':
				this.house = {
					name: '',
					text: ''
				};
				
				if(id)
				{
					if(this.allInputsStreet.length)
					{
						this.allInputsStreet.forEach(streetData => {
							if(streetData.id !== id)
								this.clearElementData(streetData);
						});
					}
				}
				
				if(this.allInputsHouses.length)
				{
					this.allInputsHouses.forEach(houseData => {
						this.clearElementData(houseData);
					});
				}
				
				if(window.checkout)
					window.checkout.selectedValues.user.address.house = {text: '', name: ''};
			
			//no break;
			// eslint-disable-next-line no-fallthrough
			case 'house':
				this.flat = '';
					if(id)
					{
						if(this.allInputsHouses.length)
						{
							this.allInputsHouses.forEach(houseData => {
								if(houseData.id !== id)
									this.clearElementData(houseData);
							});
						}
					}
					
					if(this.flatInputs.length)
					{
						this.flatInputs.forEach(flatInput => {
							flatInput.value = '';
						});
					}
					
					if(window.checkout)
						window.checkout.selectedValues.user.address.flat = "";
			//no break;
		}
	}
}